import axios from "axios"
import { serverMode } from "./config"

const localhost = 'http://localhost:4004/auth'
const production = 'https://auth.teamcgs.io/auth'

export const authUrl = axios.create({
    baseURL: (serverMode ? production : localhost),
    headers: {
        'Content-Type': 'application/json',
    }
})