import { useEffect, useState } from 'react'
import './checkInCounter.css'
import requestUrl from '../../utils/requestMethods'
import { ROSTER_SITE_VALUES } from '../../utils/definitions'
import { authUrl } from '../../utils/authRequest'
import { getKeyByValue, getMomentDateTimeNow, sumValues } from '../../utils/helper'

const CheckInCounter = ({eventName, eventInfo, setDownloadStep, setIsDownloading}) => {
    const [momentDateTimeNow, setMomentDateTimeNow] = useState(getMomentDateTimeNow('DD MMM YYYY hh:mm A'))
    const [locationsCounter, setLocationsCounter] = useState({
        AGT: 0,
        GLAS: 0,
        OFT: 0,
        SMS: 0,
    })
    const [locationsCountTotal, setLocationsCountTotal] = useState(0)
    const [disableRefresh, setDisableRefresh] = useState(false)
    const [disableDownload, setDisableDownload] = useState(true)
    let displayedLocations = []
    eventInfo?.sites?.forEach(site => {
        displayedLocations.push(ROSTER_SITE_VALUES[site])
    })

    const getCounters = async () => {
        displayedLocations.forEach( async location => {
            try {
                let config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: "same-origin",
                }
                const { data } = await requestUrl.get(`/api/checkin/counter/${location}/${eventName}`, config)
                setLocationsCounter(prev => ({...prev, [getKeyByValue(ROSTER_SITE_VALUES, location)]: data }))
                setDisableRefresh(false)
            } catch (error) {
                setDisableRefresh(false)
                console.log(error)
            }
        });
    }
    useEffect(() => {
        setLocationsCountTotal(sumValues(locationsCounter))
        if (Number(locationsCountTotal) <= 0) setDisableDownload(true)
        else setDisableDownload(false)
    }, [locationsCounter])
    
    const handleGetCounters = e => {
        setDisableRefresh(true)
        e.preventDefault()
        setMomentDateTimeNow(getMomentDateTimeNow('DD MMM YYYY hh:mm A'))
        getCounters()
    }
    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
        let str = 'Domain ID, Performed By, Site, Event Name, Admin Name, First Name, Last Name, Tower, Group \r\n'

        for( let i = 0; i < array.length; i++) {
            let line = ''
            for(let index in array[i]) {
                if(line !== '') line += ','
                line += array[i][index]
            }
            str += line + '\r\n'
        }
        return str
    }
    const downloadCSV = (data) => {
        console.log('records downloaded', data.length)
        const csvData = new Blob([convertToCSV(data)], {type: 'text/csv'})
        const csvURL = URL.createObjectURL(csvData)
        const link = document.createElement('a')
        link.href = csvURL
        link.download = `${eventName} - ${momentDateTimeNow}.csv`
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
        setDisableDownload(false)
    }
    const handleDownloadAttendance = async e => {
        setDisableDownload(true)
        setDownloadStep(0)
        setIsDownloading(true)
        e.preventDefault()
        try {
            const payload = {
                sites: displayedLocations
            }
            const response = await authUrl.post(`/extractCheckInReport`, payload, {
                onDownloadProgress: event => {
                    let percentCompleted = Math.floor(event.progress * 100)
                    setDownloadStep(percentCompleted)
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setIsDownloading(false)
                            setDisableDownload(false)
                        }, 1000);
                    }
                },
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: "same-origin",
            })
            .then( res => {
                return res
            })
            
            const { data } = response
            downloadCSV(data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const onPageLoad = async () => {
            getCounters()
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }  
    }, [eventName])

    return (
    <div className="sidePanelWrap">
        <span className='sidePanelTitle'>
            Check-in Count
        </span>
        <div className="sidePanelCounters">
            {
                displayedLocations.map((item, i) => (
                    <span key={i}>{getKeyByValue(ROSTER_SITE_VALUES, item)}: {locationsCounter[getKeyByValue(ROSTER_SITE_VALUES, item)]}</span>
                ))
            }
        </div>
        <span className='counterDate'>As of: {momentDateTimeNow}</span>
        <div className="sidePanelButtons">
            <button onClick={handleGetCounters} disabled={disableRefresh}>Refresh</button>
            <button onClick={handleDownloadAttendance} disabled={disableDownload}>Download Report</button>
        </div>
    </div>
    )
}

export default CheckInCounter