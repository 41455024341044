import './splash.css'
import carelonLogoSvg from "../../resources/carelon/carelon-logo-colored.svg";

const Splash = ({authStatus}) => {
  return (
    <div className="login">
        <div className='authHeader'>Authenticating... {authStatus} </div>
        <div className="upperSection">
            <div className="loginTitles">
                <img src={carelonLogoSvg} alt="" className="loginCompanyLogo"/>
                <span className="appName">CGS SuperApp</span>
            </div>
        </div>
    </div>
  )
}

export default Splash