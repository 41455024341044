import React, { Component } from 'react'
import RadioItem from '../RadioItem/RadioItem';

export class RadioGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formElement: props.formElement,
            getElementIndex: props.getElementIndex,
            componentRef: props.componentRef,
            elemRef: props.elemRef,
            index: props.index,
            options: []
        }
        this.updateOptions = this.updateOptions.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }
    handleSelectChange = () => {
        this.state.formElement.childElement.forEach(element => {
            const i = this.state.getElementIndex(element)
            console.log(i)
            console.log(this.state.componentRef.current[i])
            this.state.componentRef.current[i]?.current?.updateOptions()
        })
    }
    updateOptions = () => {
        console.log(`update options in ${this.state.formElement.name}`)
        let dependencyMatch = 0
        let dependencyOptions = []
        this.state.formElement.optionValues.forEach(optionValue => {
            let ruleSatisfied = 0
            optionValue.dependencyRule.forEach(rule => {
                const i = this.state.getElementIndex(rule.parent)
                console.log('parent', rule.parent)
                console.log('condition', rule.condition)
                console.log('parent value', this.state.elemRef.current[i].current.value)
                console.log('rule value', rule.value)
                switch (rule.condition) {
                    case 'notEmpty':
                        if (this.state.elemRef.current[i].current.value !== '') {
                            ruleSatisfied++
                        }
                        break;
                    case 'isEqual':
                        if (this.state.elemRef.current[i].current.value === rule.value ) {
                            console.log('satisfied')
                            ruleSatisfied++
                        }
                        break;
                    default:
                        break;
                }
            });
            console.log('rules length', optionValue.dependencyRule.length)
            console.log('ruleSatisfied count', ruleSatisfied)
            console.log('check', optionValue.dependencyRule.length === ruleSatisfied)
            if(optionValue.dependencyRule.length === ruleSatisfied) {
                dependencyOptions = optionValue.options
                dependencyMatch++;
            }
        });
        console.log('dependency match count', dependencyMatch)
        console.log('dependency options', dependencyOptions)
        if(dependencyMatch > 0) this.setState({options: dependencyOptions})
        else this.setState({ options: []})
    }

    render() {
        return (
            <div key={this.state.formElement.name}>
                <label htmlFor={this.state.formElement.name}>{this.state.formElement.displayText}</label>
                {/* <select className='formElementSelect' id={this.state.formElement.name} required onChange={this.handleSelectChange} ref={this.state.elemRef.current[this.state.index]} key={this.state.index} > */}                    
                    {
                        this.state.formElement.optionValues.map(optionValue => 
                            optionValue.dependencyRule.length === 0 ? optionValue.options.map(option => (
                                // <div className='radioItem'>
                                //     <input type="radio" name={this.state.formElement.name} value={option?.id} onChange={this.handleSelectChange} />
                                //     <label htmlFor={this.state.formElement.name}>{option?.displayText}</label>
                                // </div>
                                <RadioItem name={this.state.formElement.name} option={option} onClickAction={this.handleSelectChange}/>
                            ))
                            :
                            ''
                        )
                    }
            </div>
        )
    }
}

export default RadioGroup