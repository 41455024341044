import './landingMenuItem.css'
import placeHolderIcon from "../../resources/carelon/placeholderIcon.svg";
import { Link } from 'react-router-dom';

const LandingMenuItem = ({title, url}) => {
  return (
    <Link className="landingMenuItem" to={url}>
        <div className="iconWrap">
            <img src={placeHolderIcon} alt="" className="placeHolderIcon"/>
        </div>
        <span>{title}</span>
    </Link>
  )
}

export default LandingMenuItem