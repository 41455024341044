import './checkInHome.css'
import CheckIn from '../../components/CheckIn/CheckIn'
import { useEffect, useState } from 'react';
import CheckInCounter from '../../components/CheckInCounter/CheckInCounter';
import { EVENT_STATUS } from '../../utils/definitions';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import requestUrl from '../../utils/requestMethods';

const CheckInHome = () => {
    const [isDownloading, setIsDownloading] = useState(false)
    const [downloadStep, setDownloadStep] = useState(0)
    const [eventName, setEventName] = useState('Event Name')
    const [eventInfo, setEventInfo] = useState({})

    const getEvent = async () => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: "same-origin",
            }
            const { data } = await requestUrl.get(`/api/checkin/getEvent/${EVENT_STATUS.ACTIVE}`, config)
            setEventName(data.eventName)
            setEventInfo(data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const onPageLoad = async () => {
            getEvent()
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }  
    }, [])

    return (
        <div className='checkInWrapper'>
            <div className="checkInBanner">
                <span>{eventName}</span>
            </div>
            <div className="checkInHomeContainer">
                <div className="checkInSidePanel">
                    <CheckInCounter eventName={eventName} setDownloadStep={setDownloadStep} setIsDownloading={setIsDownloading} eventInfo={eventInfo}/>
                </div>
                
                <div className="checkInMainPanel">
                    <CheckIn eventName={eventName}/>
                </div>
            </div>
            { isDownloading && <ProgressBar step={downloadStep} />}
        </div>
    )
}

export default CheckInHome