import "./topbar.css";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import carelonLogoSvg from "../../resources/carelon/cgs_sm_h_rgb_rev.png";
import { Link, Navigate, useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logoutBtnImg from "../../resources/carelon/icons8-logout-96.png";
import { authUrl } from "../../utils/authRequest";

const TopBar = ({ toggleSideBar, toggleUserOptions }) => {
  // const history = useHistory();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const fullName = `${user.firstName} ${user.lastName}`
  const handleLogout = async () => {
    //clear cookie on server
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: "same-origin",
      };
      const res = await authUrl.get("/logout", config)
      window.location.href = '/'
    } catch (error) {
      console.log(error)
    }

    // Clear local storage and session storage
    localStorage.clear();
    sessionStorage.clear();

    // Redirect to login page
    // navigate("/");
    window.location.reload();
  };

  return (
    <div className="topBarContainer">
      {/* <div className="topBarMenuToggle" onClick={toggleSideBar}>
                <FontAwesomeIcon icon={faBars} className="topBarMenuToggleIcon" onClick={toggleSideBar} />
                <span onClick={toggleSideBar}>MENU</span>
            </div> */}
      <div className="topBarTopBarWrapper">
        <Link to="/" className="topBarLink">
          <div className="topBarCompanyLogo">
            <img src={carelonLogoSvg} alt="" className="companyLogo" />
          </div>
        </Link>
        <div className="topRightWrap">
          <div className="userInfo">
              <span className="fullName">{fullName}</span>
              <span>{user.position}, {user.tower}</span>
          </div>
          <Link onClick={() => handleLogout()} to={handleLogout} className="topBarLink">
            <div className="topBarLogout" >
              <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLogout}/>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
