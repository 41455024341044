import "./checkIn.css";
import { useState, useRef, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import requestUrl from "../../utils/requestMethods.js";
import { ERROR_TRANSLATE } from '../../utils/definitions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { authUrl } from "../../utils/authRequest.js";
import { extractErrorMessage, translateError } from "../../utils/helper.js";

const CheckIn = ({eventName}) => {
    const domainIdRef = useRef();
    const [verifyParams, setVerifyParams] = useState({
        domainId: undefined,
    });
    const [disableVerify, setDisableVerify] = useState(true)
    const [disableInput, setDisableInput] = useState(false)
    const [verifyError, setVerifyError] = useState(false)
    const [verifyErrorMsg, setVerifyErrorMsg] = useState('')
    const [loadAssocProfile, setLoadAssocProfile] = useState(false)
    const [assocProfile, setAssocProfile] = useState(null)

    const handleChange = (e) => {
        setVerifyError(false)
        setLoadAssocProfile(false)
        setAssocProfile(null)
        setDisableVerify(false)
        let input = e.target.value;
        input = input.toUpperCase();
        setVerifyParams((prev) => ({ ...prev, [e.target.id]: input }));
      };

    const handleVerify = async (e) => {
        setDisableVerify(true)
        setDisableInput(true)
        e.preventDefault();
        if (verifyParams.domainId === '') verifyParams.domainId = null
        setVerifyError(false)
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await authUrl.get(`/verifyCheckIn/${verifyParams.domainId}`, config)
            const { data } = res.data
            setLoadAssocProfile(true)
            setAssocProfile(data)
        } catch (error) {
            if (error.response?.data !== undefined) {
                console.log(error.response?.data)
                const errorMsg = translateError(extractErrorMessage(error.response?.data), ERROR_TRANSLATE)
                setVerifyError(true)
                setVerifyErrorMsg(errorMsg?.translation)
                console.log(errorMsg?.translation)
                setDisableInput(false)
                setDisableVerify(false)
            }
            else {
                setVerifyError(true)
                setVerifyErrorMsg(error.response?.data)
                console.log(error)
            }
        }
    }
    return (
        <div className='checkInContainer'>
            <h3 className="title">Associates Check-in</h3>
            
            <div className='verifySection'>
                <div className="inputContainer">
                    <input ref={domainIdRef} type="text" placeholder="Domain ID" id="domainId" className="verifyInput domainId" onChange={handleChange} maxLength="7" disabled={disableInput}/>
                    <button className="verifyButton" onClick={handleVerify} disabled={disableVerify}>Verify</button>
                </div>
                { verifyError && 
                    <div className='actionBtnContainer'>
                        <span className='errorMsg'><FontAwesomeIcon icon={faCircleExclamation} className="iconError" /> { verifyErrorMsg }</span>
                    </div>
                }
            </div>
            { loadAssocProfile && <AssociateProfile data={assocProfile} setLoadAssocProfile={setLoadAssocProfile} setAssocProfile={setAssocProfile} domainIdRef={domainIdRef} setVerifyParams={setVerifyParams} eventName={eventName} setDisableVerify={setDisableVerify} setDisableInput={setDisableInput}/> }
        </div>
    )
}

const AssociateProfile = ({data, setLoadAssocProfile, setAssocProfile, domainIdRef, setVerifyParams, eventName, setDisableVerify, setDisableInput}) => {
    const { user } = useContext(AuthContext);
    const [checkInError, setCheckInError] = useState(false)
    const [checkInErrorMsg, setCheckInErrorMsg] = useState('')
    const [checkInSuccess, setCheckInSuccess] = useState(false)
    const [checkedUser, setCheckedUser] = useState('')
    const [disableCheckIn, setDisableCheckIn] = useState(false)

    const handleCancel = e => {
        e.preventDefault();
        setLoadAssocProfile(false)
        setAssocProfile(null)
        domainIdRef.current.value = ''
        setVerifyParams({
            domainId: undefined,
        })
        setDisableInput(false)
        setDisableVerify(false)
    }

    const handleCheckIn = async (e) => {
        e.preventDefault();
        setDisableCheckIn(true)
        const checkInData = {
            domainId: data.domainId,
            action: 'Check-in',
            performedBy: user.domainId,
            site: data.location,
            details: eventName
        }
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await requestUrl.post(`/api/user/checkInTownhall`, checkInData, config)
            const { data } = res
            setCheckedUser(data)
            setCheckInSuccess(true)
            setAssocProfile(null)
            domainIdRef.current.value = ''
            setVerifyParams({
                domainId: undefined,
            })
            setDisableInput(false)
            setDisableVerify(false)
        } catch (error) {
            if (error.response?.data !== undefined) {
                setCheckInError(true)
                setCheckInErrorMsg(error.response?.data)
                console.log(error.response?.data)
            }
            else {
                setCheckInError(true)
                setCheckInErrorMsg(error.response?.data)
                console.log(error)
            }
        }
    }

    return (
        <div>
            {
                !checkInSuccess ? (
                    <div className="assocInfoWrapper">
                        <div className="textAreaWrapper">
                            <div className='dataPanel'>
                                <textarea
                                    className={`dataTextBox ${data.successMessage ? "successMessage" : ""}`}
                                    readOnly
                                    value={data.successMessage || `Name: ${data.firstName} ${data.lastName} \nGender: ${data.gender}  \nPosition: ${data.position} \nTower: ${data.tower} \nGroup: ${data.group} \nLocation: ${data.location} \nManager: ${data.manager} \nEmployment Status: ${data.active ? 'Active' : 'Inactive'}`
                                }
                                />
                            </div>
                            
                        </div>
                        <div className="actionBtnContainer">
                            <div className="actionBtnWrapper">
                                <button className="cancelCheckInBtn" onClick={handleCancel}>Cancel</button>
                                <button className="checkInBtn" onClick={handleCheckIn} disabled={disableCheckIn}>Check-in</button>
                            </div>
                            { checkInError && <span className="errorMsg"><FontAwesomeIcon icon={faCircleExclamation} className="iconError" /> { checkInErrorMsg }</span>}
                        </div>
                    </div>
                ) 
                :
                (
                    <div className="successWrapper">
                        <span className='successMsg'><FontAwesomeIcon icon={faCircleCheck} className="iconSuccess" /> Associate {checkedUser?.domainId} was checked in successfully</span>
                    </div>
                )
            }
            
        </div>
    )
}

export default CheckIn
