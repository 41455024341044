import "./home.css";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import SideBar from "../../components/SideBar/SideBar";
import HomeFeed from "../../components/HomeFeed/HomeFeed";
import HomeWing from "../../components/Wing/HomeWing";
import { Routes, Route } from "react-router-dom";
import useFetch from "../../hooks/useFetch.js";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { sampleForm } from '../../utils/sampleForm.js'
import { includesAny } from "../../utils/helper.js";

const Home = () => {
  const { data } = useFetch("/api/sidebar")
  const { user } = useContext(AuthContext)
  const { permissions } = user
  console.log('sidebar', data)
  console.log('permissions', permissions)
  let routesByAccess = []
  data.forEach(sidebarItem => {
    if (includesAny(sidebarItem.access, permissions)) {
      console.log(sidebarItem.title)
      routesByAccess.push(sidebarItem)
    }
  });
  console.log('routesByAccess', routesByAccess)
  // const { data, loading, error } = useFetch('/api/forms/findForm');
  // let sampleForm = data;
  return (
    <div className="homeContainer">
      <SideBar data={data} />
      <Routes>
        {
          routesByAccess.map((item) => (
            <Route key={item.path} path={item.path} element={<HomeFeed section={item?.title} />} />
          ))
        }
        <Route path="/" element={<HomeFeed section={routesByAccess[0]?.title} />} />
      </Routes>
      {
        user.permissions.includes("kickoff") || user.permissions.includes("townhall")? '' : <HomeWing />
      }
      {/* <HomeWing /> */}
      {/* {sampleForm && <DynamicForm formData={sampleForm} />} */}
    </div>
  );
};

export default Home;
