import moment from 'moment-timezone'

export const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time))
}

export const includesAny = (arr, values) => values.some(v => arr.includes(v));

export const translateError = (error, arr) => arr.find(obj => obj.response === error)

export const extractErrorMessage = (htmlResponse) => {
    let span = document.createElement('span')
    span.innerHTML = htmlResponse
    let message = span.textContent || span.innerText
    let startIndex = htmlResponse.indexOf(':')
    let endIndex = htmlResponse.indexOf('<br>')
    message = htmlResponse.substring(startIndex + 2, endIndex)
    return message
}
export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}
export const sumValues = (obj) => Object.keys(obj).reduce((acc, value) => acc + obj[value], 0)

export const getMomentDateTimeNow = (format) => {
    moment.tz.setDefault("Asia/Taipei");
    const nowMomentString = moment().tz('Asia/Taipei').format(format)
    return nowMomentString
}