export const ACTION_TYPES = {
  START: "START",
  SUCCESS: "SUCCESS",
  CHANGE_STATUS: "CHANGE_STATUS",
  FAILURE: "FAILURE",
  LOGOUT: "LOGOUT",
};
export const DEFAULT_NAMES = {
  APP_NAME: "CGS SuperApp",
};
export const USER_ROLES = {
  ASSOCIATE: "associate",
  MANAGER: "manager",
  ADMINISTRATOR: "administrator",
  KICKOFF: "kickoff",
};
export const EVENT = {
  KICKOFF: 'Carelon Kick-Off Party',
  ASSOCIATES_TOWNHALL: 'All Associates Town Hall',
  LEADERS_TOWNHALL: 'All Leaders Town Hall',
}
export const EVENT_STATUS = {
  CREATED: 'Created',
  ACTIVE: 'Active',
  COMPLETED: 'Completed'
}

export const REDIRECT_URL = {
  BASE_LOCAL: 'http://localhost:3000',
  BASE_PROD: 'https://teamcgs.io',
  AUTH_LOCAL: 'http://localhost:3005/redirect/base',
  AUTH_PROD: 'https://auth.teamcgs.io',
}

export const AUTH_STATUS = {
  AUTH_PROCESSING: "Authenticating...",
  AUTH_AUTHENTICATED: "Associate authenticated.",
  AUTH_NOT_AUTHENTICATED: "Associate is not authenticated.",
  AUTH_NOT_AUTHORIZED: "Associate is not authorized."
}

export const LANDING_MENU = [
  {
    title: "Training",
    local: 'http://localhost:3006',
    server: 'https://training.teamcgs.io'
  },
  {
    title: "Service",
    local: 'http://localhost:3007',
    server: 'https://service.teamcgs.io'
  },
  {
    title: "Workplace",
    local: 'http://localhost:3008',
    server: 'https://workplace.teamcgs.io'
  },
  {
    title: "Cara",
    local: 'http://localhost:3009',
    server: 'https://cara.teamcgs.io'
  },
]

export const ERROR_TRANSLATE = [
  {
    response: 'Get Transaction Failed',
    translation: 'Associate Not Found'
  }
]

export const ROSTER_SITE_VALUES = {
  GLAS: 'Manila - GLAS Tower',
  AGT: 'Taguig City - Alliance Global',
  OFT: 'Iloilo City - One Fintech Place',
  SMS: 'Iloilo City - SM Strata'
}