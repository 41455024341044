import LandingMenu from '../../components/LandingMenu/LandingMenu'
import './landing.css'

const Landing = () => {
  return (
    <div className='landingContainer'>
        <LandingMenu />
    </div>
  )
}

export default Landing