import './progressBar.css'

const ProgressBar = ({step}) => {
  return (
    <div className='progressBarModal'>
        <progress value={step} max={100}/>
        <span>{`${step}% Complete`}</span>
    </div>
  )
}

export default ProgressBar